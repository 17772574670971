// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-home-components-about-js": () => import("/opt/build/repo/src/pages/Home/components/About.js" /* webpackChunkName: "component---src-pages-home-components-about-js" */),
  "component---src-pages-home-components-contact-js": () => import("/opt/build/repo/src/pages/Home/components/Contact.js" /* webpackChunkName: "component---src-pages-home-components-contact-js" */),
  "component---src-pages-home-components-header-js": () => import("/opt/build/repo/src/pages/Home/components/Header.js" /* webpackChunkName: "component---src-pages-home-components-header-js" */),
  "component---src-pages-home-components-navigation-js": () => import("/opt/build/repo/src/pages/Home/components/Navigation.js" /* webpackChunkName: "component---src-pages-home-components-navigation-js" */),
  "component---src-pages-home-components-portfolio-js": () => import("/opt/build/repo/src/pages/Home/components/Portfolio.js" /* webpackChunkName: "component---src-pages-home-components-portfolio-js" */),
  "component---src-pages-home-components-services-js": () => import("/opt/build/repo/src/pages/Home/components/Services.js" /* webpackChunkName: "component---src-pages-home-components-services-js" */),
  "component---src-pages-home-index-js": () => import("/opt/build/repo/src/pages/Home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-hosting-components-header-js": () => import("/opt/build/repo/src/pages/Hosting/components/Header.js" /* webpackChunkName: "component---src-pages-hosting-components-header-js" */),
  "component---src-pages-hosting-components-navigation-js": () => import("/opt/build/repo/src/pages/Hosting/components/Navigation.js" /* webpackChunkName: "component---src-pages-hosting-components-navigation-js" */),
  "component---src-pages-hosting-index-js": () => import("/opt/build/repo/src/pages/Hosting/index.js" /* webpackChunkName: "component---src-pages-hosting-index-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-james-components-about-about-js": () => import("/opt/build/repo/src/pages/James/components/about/about.js" /* webpackChunkName: "component---src-pages-james-components-about-about-js" */),
  "component---src-pages-james-components-about-social-list-js": () => import("/opt/build/repo/src/pages/James/components/about/socialList.js" /* webpackChunkName: "component---src-pages-james-components-about-social-list-js" */),
  "component---src-pages-james-components-education-education-js": () => import("/opt/build/repo/src/pages/James/components/education/education.js" /* webpackChunkName: "component---src-pages-james-components-education-education-js" */),
  "component---src-pages-james-components-experience-experience-js": () => import("/opt/build/repo/src/pages/James/components/experience/experience.js" /* webpackChunkName: "component---src-pages-james-components-experience-experience-js" */),
  "component---src-pages-james-components-interests-interests-js": () => import("/opt/build/repo/src/pages/James/components/interests/interests.js" /* webpackChunkName: "component---src-pages-james-components-interests-interests-js" */),
  "component---src-pages-james-components-projects-projects-js": () => import("/opt/build/repo/src/pages/James/components/projects/projects.js" /* webpackChunkName: "component---src-pages-james-components-projects-projects-js" */),
  "component---src-pages-james-components-side-nav-side-nav-js": () => import("/opt/build/repo/src/pages/James/components/sideNav/sideNav.js" /* webpackChunkName: "component---src-pages-james-components-side-nav-side-nav-js" */),
  "component---src-pages-james-components-skills-skills-items-js": () => import("/opt/build/repo/src/pages/James/components/skills/skillsItems.js" /* webpackChunkName: "component---src-pages-james-components-skills-skills-items-js" */),
  "component---src-pages-james-components-skills-skills-list-js": () => import("/opt/build/repo/src/pages/James/components/skills/skillsList.js" /* webpackChunkName: "component---src-pages-james-components-skills-skills-list-js" */),
  "component---src-pages-james-components-work-work-js": () => import("/opt/build/repo/src/pages/James/components/work/work.js" /* webpackChunkName: "component---src-pages-james-components-work-work-js" */),
  "component---src-pages-james-index-js": () => import("/opt/build/repo/src/pages/James/index.js" /* webpackChunkName: "component---src-pages-james-index-js" */),
  "component---src-pages-privacy-index-js": () => import("/opt/build/repo/src/pages/Privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-terms-index-js": () => import("/opt/build/repo/src/pages/Terms/index.js" /* webpackChunkName: "component---src-pages-terms-index-js" */),
  "component---src-pages-thank-you-components-header-js": () => import("/opt/build/repo/src/pages/ThankYou/components/Header.js" /* webpackChunkName: "component---src-pages-thank-you-components-header-js" */),
  "component---src-pages-thank-you-components-ty-navigation-js": () => import("/opt/build/repo/src/pages/ThankYou/components/TYNavigation.js" /* webpackChunkName: "component---src-pages-thank-you-components-ty-navigation-js" */),
  "component---src-pages-thank-you-index-js": () => import("/opt/build/repo/src/pages/ThankYou/index.js" /* webpackChunkName: "component---src-pages-thank-you-index-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

